import React from 'react';
import {PageColor} from '../components/page-color';
import {Content} from '../components/content';
import {Gallery} from '../components/gallery';
import {MediaObject} from '../components/media-object';

export default class Cases extends React.Component {
    get _case() {
        return this.props.pageContext._case;
    }

    render = () => (
        <main className="case">
            <PageColor color="terracotta" nav="pigeon"/>
            <div className="case__left">
                <div className="case__left__image">
                    <MediaObject media={this._case.mainImageLeft}/>
                </div>
            </div>
            <div className="case__right">
                <div className="case__content">
                    <h1>
                        <div className="title-poppins case__logo">
                          {this._case.client}
                            <MediaObject media={this._case.logo}/>
                        </div>

                        <div className="title-ivy">{this._case.name}</div>
                    </h1>

                    <div className="case__goal"><Content document={this._case.mainGoal}/></div>

                    <div className="case__meta">
                        {this._case.services && <div className="case__meta__col">
                            <strong className="title-montserrat">Services</strong>
                            <div>
                                {this._case.services.split('\n').map((item, key) => <React.Fragment
                                    key={key}>{item}<br/></React.Fragment>)}
                            </div>
                        </div>}
                    </div>
                </div>

                {this._case.mainImageRight && <MediaObject media={this._case.mainImageRight}/>}

                <div className="case__content">
                    <div className="case__content__block">
                        <div className="case__content__block__title title-poppins">The brief —</div>
                        <div className="case__content__block__body">
                            <Content document={this._case.briefingClient}/>
                        </div>
                    </div>

                    <Gallery photos={this._case.galleryBelowTheBrief}/>

                    <div className="case__content__block">
                        <div className="case__content__block__title title-poppins">What we did —</div>
                        <div className="case__content__block__body">
                            <Content document={this._case.whatWeDid}/>
                        </div>
                    </div>

                    <Gallery photos={this._case.galleryBelowWhatWeDid}/>
                </div>

                <div className="case__results">
                    <div className="case__content">
                        <div className="case__results__block">
                            <div className="case__results__block__left">
                                {this._case.resultsImage && <MediaObject media={this._case.resultsImage}/>}
                                <div className="case__results__title">
                                    <div className="title-poppins">Results</div>
                                    <div className="title-ivy">In Numbers</div>
                                </div>
                            </div>
                            <div className="case__results__block__right">
                                <div className="case__result__value title-poppins">{this._case.resultsInNumbers1}</div>
                                <div className="case__result__label">{this._case.resultsInNumbers1Label}</div>

                                <div className="case__result__value title-poppins">{this._case.resultsInNumbers2}</div>
                                <div className="case__result__label">{this._case.resultsInNumbers2Label}</div>

                                <div className="case__result__value title-poppins">{this._case.resultsInNumbers3}</div>
                                <div className="case__result__label">{this._case.resultsInNumbers3Label}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
